import logo from "./logo.webp"

const  clientData = {
   client_entity: 24,
   attorney_firm: 'tejeslaw.com',
    attorney_name: 'Josh Tejes',
    attorney_number: '+1-919-646-2654',
    attorney_email: 'josh@tejeslaw.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_explainer_-_warren_%26_migliaccio+(720p).mp4',
    mainColor: '#D3BC90',
    secondaryColor: '#44465D',
    siteLink: 'http://tejeslaw.com/',
    logo
}

export default clientData